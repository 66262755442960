import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { BidPriceService } from "../../shared/services/bid-sprice.service";
import { AppSessionService } from "../../shared/services/app-session.service";
import { Subscription } from "rxjs/internal/Subscription";
import { AppConsts } from "../../shared/AppConsts";

@Component({
  selector: "dg-bid-price",
  templateUrl: "./bid-price.component.html",
  styleUrls: ["./bid-price.component.css"],
})
export class BidPriceComponent implements OnInit, OnDestroy, AfterViewInit {
  showBidPriceText: boolean = true;
  showDiv: boolean = true;
  bidPriceClasses: string;
  footerClasses: string;
  loadingContainerClasses: string = "loadingContainerClasses";
  loadingClasses: string = "loadingClasses";
  marketOpen: boolean = true;
  marketStatus: string = "";
  marketIndicatorClass: string = "";
  loadingTextClasses: string = "loadingTextClasses";
  currentDatetime: Date;
  showSpotPriceError: boolean = false;
  showLoading: boolean = true;
  spotPriceDataArray: Object[];
  isSpotPriceRefreshEnabled: boolean;
  isUserAuthenticatedSubscription: Subscription;
  copyrightYear: string = AppConsts.copyrightYear;

  constructor(
    private appSessionService: AppSessionService,
    private bidPriceService: BidPriceService
  ) {
      this.initRefreshSpotPrice();
  }

  ngOnInit() {
    if (this.appSessionService.user) {
      this.refreshBidPrice();
    } else {
      this.getBidPriceFromServer();
      this.getMarketStatusFromServer();
    }
  }

  ngAfterViewInit() {
    if (this.appSessionService.user) {
      this.doRefreshBidPrice();
    }
  }

  ngOnDestroy() {
    this.isUserAuthenticatedSubscription.unsubscribe();
  }

  initRefreshSpotPrice() {
    this.isUserAuthenticatedSubscription = this.appSessionService.isUserAuthenticated.subscribe(
      (status: boolean) => {
        if (status) {
          this.doRefreshBidPrice();
        }
      }
    );
  }

  ToggleBidPrice() {
    this.showBidPriceText = !this.showBidPriceText;
    if (!this.showBidPriceText) {
      this.bidPriceClasses = "animatBidPriceHeight";
      this.footerClasses = "animateFooterHeight";
      //$('#contentContainer').removeClass('margin-bottom-spotPriceClose');
      $("#contentContainer").addClass("margin-bottom-spotPriceOpen");
    } else {
      this.bidPriceClasses = "";
      this.footerClasses = "";
      $("#contentContainer").removeClass("margin-bottom-spotPriceOpen");
      //$('#contentContainer').addClass('margin-bottom-spotPriceClose');
    }
  }

  doRefreshBidPrice(): void {
    if (this.isSpotPriceRefreshEnabled) {
      return;
    }

    this.isSpotPriceRefreshEnabled = true;

    setInterval(() => {
      this.refreshBidPrice();
    }, 15000);
  }

  refreshBidPrice(): void {
    this.getBidPriceFromServerForLoggedUser();
    this.getMarketStatusFromServerForLoggedUser();
  }

  getBidPriceFromServer(): void {
    this.bidPriceService.getSpotPrices().subscribe((result: any) => {
      if (this.showLoading) {
        this.showLoading = false;
      }

      this.processBidPriceData(result.data);
    });
  }

  getBidPriceFromServerForLoggedUser(): void {
    this.bidPriceService
      .getSpotPricesForLoggedUser()
      .subscribe((result: any) => {
        if (this.showLoading) {
          this.showLoading = false;
        }

        this.processBidPriceData(result.data);
      });
  }

    processBidPriceData(spotPriceData: any): void {
    this.currentDatetime = this.parseTimestamp(spotPriceData.timestamp);
    this.spotPriceDataArray = [
      {
        Metal: "Gold",
        Bid: spotPriceData.goldBid,
        Ask: spotPriceData.goldAsk,
        Change: spotPriceData.goldChange,
        DeltaClass:
          parseFloat(spotPriceData.goldChange) > 0
            ? "glyphicon glyphicon-triangle-top greenTopTriangle"
            : "glyphicon glyphicon-triangle-bottom redBottomTriangle",
      },
      {
        Metal: "Silver",
        Bid: spotPriceData.silverBid,
        Ask: spotPriceData.silverAsk,
        Change: spotPriceData.silverChange,
        DeltaClass:
          parseFloat(spotPriceData.silverChange) > 0
            ? "glyphicon glyphicon-triangle-top greenTopTriangle"
            : "glyphicon glyphicon-triangle-bottom redBottomTriangle",
      },
      {
        Metal: "Platinum",
        Bid: spotPriceData.platinumBid,
        Ask: spotPriceData.platinumAsk,
        Change: spotPriceData.platinumChange,
        DeltaClass:
          parseFloat(spotPriceData.platinumChange) > 0
            ? "glyphicon glyphicon-triangle-top greenTopTriangle"
            : "glyphicon glyphicon-triangle-bottom redBottomTriangle",
      },
      {
        Metal: "Palladium",
        Bid: spotPriceData.palladiumBid,
        Ask: spotPriceData.palladiumAsk,
        Change: spotPriceData.palladiumChange,
        DeltaClass:
          parseFloat(spotPriceData.palladiumChange) > 0
            ? "glyphicon glyphicon-triangle-top greenTopTriangle"
            : "glyphicon glyphicon-triangle-bottom redBottomTriangle",
      },
    ];
  }

  getMarketStatusFromServer(): void {
    this.bidPriceService.getMarketStatus().subscribe((marketStatus: any) => {
      this.processMarketStatusData(marketStatus);
    });
  }

  getMarketStatusFromServerForLoggedUser(): void {
    this.bidPriceService
      .getMarketStatusForLoggedUser()
      .subscribe((marketStatus: any) => {
        this.processMarketStatusData(marketStatus);
      });
  }

  processMarketStatusData(marketStatus: any): void {
    if (marketStatus) {
      for (let metal of marketStatus) {
        if (
          metal.status.toLowerCase() === "close" ||
          metal.status.toLowerCase() === "closed"
        ) {
          this.marketOpen = false;
        }
      }
      this.marketStatus = this.marketOpen ? "open" : "closed";
      this.marketIndicatorClass = this.marketOpen
        ? "glyphicon glyphicon-ok-circle circleOk market-indicator-open"
        : "glyphicon glyphicon-remove-circle circleRemove market-indicator-close";
    }
    }
    parseTimestamp(timestamp: string): Date {
        const parts = timestamp.split(' ');
        const year = new Date().getFullYear();
        const month = this.getMonthIndex(parts[1]);
        const day = parseInt(parts[2], 10);
        const time = parts[3].split(':');
        let hour = parseInt(time[0], 10);
        const minute = parseInt(time[1], 10);
        const second = parseInt(time[2], 10);
        if (parts[4] === 'PM' && hour !== 12) {
            hour += 12;
        } else if (parts[4] === 'AM' && hour === 12) {
            hour = 0;
        }
        return new Date(year, month, day, hour, minute, second);
    }
    getMonthIndex(monthName: string): number {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months.findIndex(month => month.toLowerCase() === monthName.toLowerCase());
    }
}
