﻿import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ISubDivision, IDivision } from "../../shared/models";
import { FormControl, FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { HomeService } from "./services/home.service";
import { MessageService } from "../../shared/services/message.service";
import { AppConsts } from '../../shared/AppConsts';
import { State } from "../../shared/AppEnum";
import { environment } from '../../../environments/environment';

export interface DialogData {
    country: string;
    siteKey: string;
    divisions: IDivision[];
    subDivisions: ISubDivision[];
    filteredSubDivisions: ISubDivision[];
}

@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'NewAccountTemplate.html',
    styleUrls: ["./home.component.css"],
    providers: [HomeService],
})
export class DialogContentExampleDialog {

    public newAccountForm: FormGroup;
    public siteKey: string;

    constructor(
        public dialogRef: MatDialogRef<DialogContentExampleDialog>,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    ngOnInit() {
        this.newAccountForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^\\S+@\\S+\\.\\S+$')]],
            password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\W]{8,15}$')]],
            confirmPassword: ['', Validators.required],
            company: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['', Validators.required],
            phone: ['', Validators.required],
            hasActiveTradingAccount: [false],
            recaptcha: [''],
        });
        this.onSelect(this.data.country);
        this.siteKey = this.data.siteKey;
    }

    onCreateAccount(form: FormGroup): void {
        dg.ui.setBusy();
        var ref = this.dialogRef;
        var resMessage;
        var resContent;
        this.homeService.register(form.value).subscribe((res: any) => {
            ref.close();
            if (res._body === 'Error') {
                resMessage = "An Error Occured When Creating The Account";
                return this.messageService.error(
                    resMessage
                );
            }
            else if (res._body === 'Invalid Captcha') {
                resMessage = "Invalid Captcha";
                return this.messageService.error(
                    resMessage
                );
            }
            else if (res._body === 'Account Created') {
                if (this.newAccountForm.get('hasActiveTradingAccount').value === true)
                    resMessage =
                        "Your application has been received.You will be notified shortly on the status of your request.";
                else {
                    resMessage =
                        "Your request has been received. Please fill out the dealer application by clicking on the following link.";
                    resContent = {
                        element: "a",
                        attributes: {
                            href: (environment.production) ? "https://dillongage.com/application/" : "https://stage.dillongage.com/application/" ,
                            text: "Complete Account Application"
                        },
                    }
                }
                this.messageService.success(
                    resMessage,
                    '',
                    resContent
                );
            }
            dg.ui.clearBusy();
            //reset captcha after registration
            this.newAccountForm.patchValue({ captcha: '' });
        }, (error: any) => {
        });
    }

    onSelect(countryLongIso: string) {
        this.newAccountForm.patchValue({ country: countryLongIso });
        this.data.filteredSubDivisions = this.data.subDivisions.filter((item) => item.countryLongIso == countryLongIso);
        if (this.data.filteredSubDivisions.length > 0)
            this.newAccountForm.patchValue({ state: this.data.filteredSubDivisions[0].subdivisionAbbreviation });
        else
            this.newAccountForm.patchValue({ state: State.NoState });
    }

    localChanged(event: any) {
        var country = event.target.value;
        var state = this.newAccountForm.get('state').value;
        this.newAccountForm.patchValue({ country: this.data.divisions.find(e => e.countryLongISO === country || e.countryName === country).countryLongISO });
        this.onSelect(this.newAccountForm.get('country').value);
        this.newAccountForm.patchValue({
            state: this.data.filteredSubDivisions
                .find(e => e.subdivisionAbbreviation === state || e.subdivisionName === state).subdivisionAbbreviation
        });
    }

    onPasswordChange() {
        if (this.newAccountForm.get('confirmPassword').value && this.newAccountForm.get('confirmPassword').value == this.newAccountForm.get('password').value) {
            this.newAccountForm.get('confirmPassword').setErrors(null);
        } else if (!this.newAccountForm.get('confirmPassword').value) {
            this.newAccountForm.get('confirmPassword').setErrors({ required: true });
        } else {
            this.newAccountForm.get('confirmPassword').setErrors({ mismatch: true });
        }
    }

    captchaState(): boolean {
        return this.newAccountForm.get('recaptcha').value && this.newAccountForm.get('recaptcha').value.length > 0;
    }
    handleCaptchaSuccess(captchaResponse: string): void {
        this.newAccountForm.patchValue({ recaptcha: captchaResponse });
    }
    handleCaptchaClear(): void {
        this.newAccountForm.patchValue({ recaptcha: '' });
    }
    handleCaptchaClear1(): void {
        this.newAccountForm.patchValue({ recaptcha: '' });
    }
}